// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import "./AccountSwitcherInline.css";

import { useState } from "react";
import { Button, View, SelectField } from "@aws-amplify/ui-react";

// import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";

// import { API, graphqlOperation } from "aws-amplify";
// import { getAllowedAccounts } from "../../graphql/queries.ts";

import { useNotificationsContext } from "../Notifications/NotificationsAndNotificationsContext";

function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}
// function isObject(obj) {
// 	// noinspection RedundantConditionalExpressionJS
// 	return typeof obj === "object" && obj !== null ? true : false;
// }
function isString(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (typeof obj === "string" || obj instanceof String) ? true : false;
}

const componentName = "AccountSwitcherInline";
export default function AccountSwitcherInline({ className, allowedAccounts }) {
	const addNotification = useNotificationsContext();
	// const [isDisplaySpinner, setIsDisplaySpinner] = useState(false);

	const [selectedAccountId, setSelectedAccountId] = useState(null);
	const [isDisplayChangeButton, setIsDisplayChangeButton] = useState(false);

	// useEffect(() => {
	// 	(async function() {
	// 		await retrieveData();
	// 	})();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const retrieveData = async () => {
	// 	setIsDisplaySpinner(true);
	// 	try {
	// 		let variables = {
	// 			"account": localStorage.getItem("accountId")
	// 		};
	// 		let rawResponse;
	// 		const startTime = new Date().getTime(); // TODO debug
	// 		console.log("== GRAPH-QL QUERY [getAllowedAccounts] - START: "); // TODO debug
	// 		rawResponse = await API.graphql(graphqlOperation(getAllowedAccounts, variables));
	// 		console.log("== GRAPH-QL QUERY [getAllowedAccounts] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
	// 		let allowedAccounts = (
	// 			rawResponse && rawResponse.data && rawResponse.data.getAllowedAccounts
	// 			&& isArray(rawResponse.data.getAllowedAccounts.items)
	// 		)
	// 			? rawResponse.data.getAllowedAccounts.items
	// 			: null;
	// 		setAllowedAccounts(allowedAccounts);
	// 		setIsAllowSwitchAccounts(isArray(allowedAccounts) && allowedAccounts.length > 1);
	// 		isAllowSwitchAccounts = (isArray(allowedAccounts) && allowedAccounts.length > 1);
	//
	// 		if (isString(localStorage.getItem("accountId"))) {
	// 			let prevAccountId = localStorage.getItem("accountId");
	// 			let isValidAccountId = false;
	// 			for (let allowedAccountWrapper of allowedAccounts) {
	// 				if (isString(prevAccountId) && isString(allowedAccountWrapper.id) && prevAccountId === allowedAccountWrapper.id) {
	// 					isValidAccountId = true;
	// 					break;
	// 				}
	// 			}
	// 			if (isValidAccountId) {
	// 				setSelectedAccountId(prevAccountId);
	// 			}
	// 		}
	// 	} catch (err) {
	// 		setIsAllowSwitchAccounts(false);
	// 		apiErrorsHandler(err);
	// 	} finally {
	// 		setIsDisplaySpinner(false);
	// 	}
	// };

	function updateCurrentAccountId() {
		let previousSelectedAccountId = localStorage.getItem("accountId");
		if (isString(previousSelectedAccountId) && isString(selectedAccountId) && previousSelectedAccountId === selectedAccountId) {
			addNotification({
				"componentName": componentName,
				"text": "Account has not been changed (has already been selected earlier)",
				"type": "info",
				"seconds": 4,
				"header": undefined
			});
		} else if (isString(selectedAccountId)) {
			localStorage.setItem("accountId", selectedAccountId);
			window.location.reload();
			addNotification({
				"componentName": componentName,
				"text": "Account has been changed",
				"type": "success",
				"seconds": 4,
				"header": undefined
			});
		}
	}

	// function apiErrorsHandler(err) {
	// 	console.error(err);
	// 	let errorMessage;
	// 	if (err && err.response && err.response.data) {
	// 		console.error(err.response.data);
	// 		errorMessage = err.response.data;
	// 	} else if (err.message) {
	// 		console.error(err.message);
	// 		errorMessage = err.message;
	// 	} else {
	// 		console.error(err);
	// 		errorMessage = JSON.stringify(err);
	// 	}
	// 	if (errorMessage) {
	// 		// noinspection JSValidateTypes
	// 		addNotification({
	// 			"componentName": componentName,
	// 			"text": errorMessage,
	// 			"type": "error",
	// 			"seconds": 4,
	// 			"header": undefined
	// 		});
	// 	}
	// }

	// noinspection RequiredAttributes
	return (
		<div className={(className ? className : "")}>
			{/*{*/}
			{/*	(isAllowSwitchAccounts) &&*/}
			{/*	<div>*/}
					{
						(isArray(allowedAccounts) && allowedAccounts.length > 0) &&
						<View as="section" className={"account-switcher-inline-content-wrapper"}>
							{/*suppress RequiredAttributes*/}
							<SelectField
								variation="quiet"
								style={{ marginBottom: "1em" }}
								defaultValue={localStorage.getItem("accountId")}
								onChange={(e) => {
									setSelectedAccountId(e.target.value);
									setIsDisplayChangeButton(e.target.value !== localStorage.getItem("accountId"))
								}}
							>
								{
									allowedAccounts.map((item, index) => (<option key={index} value={item.id}>
										{item.name}
									</option>))
								}
							</SelectField>
							{
								(isDisplayChangeButton) &&
								<Button variation="link" style={{ marginTop: "1em", width: "100%" }}
								        onClick={() => updateCurrentAccountId()}>
									Switch account
								</Button>
							}
						</View>
					}
			{/*	</div>*/}
			{/*}*/}
		</div>
	);
}
