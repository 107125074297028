// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import "./NotificationsContextProvider.css";

import { useCallback, useContext, useState, createContext } from "react";
import { Alert } from "@aws-amplify/ui-react";

const NotificationsContext = createContext([]);
export default NotificationsContext;

function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}

// const componentName = "NotificationsContextProvider";
export function NotificationsContextProvider({ children, className }) {
	const [notifications, setNotifications] = useState([]);
	let shadowCopyNotifications = [];

	const addNotification = useCallback(
		function (paramsWrapper) {
			if (!paramsWrapper) {
				return;
			}
			let componentName = (paramsWrapper.componentName)
				? paramsWrapper.componentName
				: String(paramsWrapper.componentName);
			let text = (paramsWrapper.text) ? paramsWrapper.text : String(paramsWrapper.text);
			let type = (paramsWrapper.type && (paramsWrapper.type === "success" || paramsWrapper.type === "info"
					|| paramsWrapper.type === "warning" || paramsWrapper.type === "error"))
				? paramsWrapper.type
				: "info";
			let seconds = (paramsWrapper.seconds && Number.isInteger(paramsWrapper.seconds)
				&& paramsWrapper.seconds >= 0 && paramsWrapper.seconds <= 60) ? paramsWrapper.seconds : 4;
			let header = paramsWrapper.header;
			let notificationToAdd = {
				"componentName": componentName,
				"type": type,
				"text": text,
				"header": header,
				"seconds": seconds
			};

			// Prohibition of placing duplicate notifications in an array
			let isNotificationAlreadyAdded = false;
			const fieldsArrayForCheckUniqueness = [ "componentName", "type", "text", "header", "seconds" ];
			if (shadowCopyNotifications && isArray(shadowCopyNotifications)) {
				for (let notification of shadowCopyNotifications) {
					let counter = 0;
					for (let fieldName of fieldsArrayForCheckUniqueness) {
						let field1 = notification[fieldName];
						let field2 = notificationToAdd[fieldName];
						if (field1 === field2) {
							counter++;
						}
					}
					if (counter === fieldsArrayForCheckUniqueness.length) {
						isNotificationAlreadyAdded = true;
					}
				}
			}
			if (isNotificationAlreadyAdded) {
				console.info("Notification has already been added to queue: " + JSON.stringify(notificationToAdd));
				return;
			}

			// generate random message Guid
			const randomGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
				// eslint-disable-next-line no-mixed-operators
				const r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			shadowCopyNotifications.push(notificationToAdd);
			notificationToAdd["id"] = randomGuid;
			setNotifications(currentArray => [notificationToAdd, ...currentArray]);

			// schedule remove notification after N seconds
			let timeout = seconds * 1000;
			setTimeout(function() {
				setNotifications(currentArray => currentArray.filter(item => item.id && item.id !== randomGuid));
				// Delete notification from shadow copy
				if (shadowCopyNotifications && shadowCopyNotifications.length) {
					for (let i = shadowCopyNotifications.length - 1; i >= 0; i--) {
						if (randomGuid === shadowCopyNotifications[i].id) {
							shadowCopyNotifications.splice(i, 1);
							break;
						}
					}
				}
			}, timeout);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setNotifications]
	);

	const dismissAlert = useCallback(
		function (id) {
			// Delete notification from shadow copy
			if (id && shadowCopyNotifications && shadowCopyNotifications.length) {
				for (let i = shadowCopyNotifications.length - 1; i >= 0; i--) {
					if (id === shadowCopyNotifications[i].id) {
						shadowCopyNotifications.splice(i, 1);
						break;
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setNotifications]
	);

	return (
		<NotificationsContext.Provider value={addNotification}>
			{children}
			<div className={"alert-messages-wrapper" + (className ? className : "")}>
				{notifications.map((notification, index) => (
					<Alert key={index} isDismissible={true}
					       variation={notification.type}
					       heading={notification.header}
					       onDismiss={() => dismissAlert(notification.id)}>
						{notification.text}
					</Alert>
				))}
			</div>
		</NotificationsContext.Provider>
	);
}

export function useNotificationsContext() {
	return useContext(NotificationsContext);
}
