// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import "./Sidebar.css";

import { useTheme } from "@aws-amplify/ui-react";

import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import * as AllIcons from "@mui/icons-material";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { API, graphqlOperation } from "aws-amplify";
import { getSidebarMenuItems } from "../../graphql/queries.ts";

import { useNotificationsContext } from "../Notifications/NotificationsAndNotificationsContext";

const drawerWidth = "auto";

function isString(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (typeof obj === "string" || obj instanceof String) ? true : false;
}
function isObject(obj) {
	// noinspection RedundantConditionalExpressionJS
	return typeof obj === "object" && obj !== null ? true : false;
}
function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}

const componentName = "Sidebar";
function Sidebar({signOutFunction, className, isNavigationDrawerOpen, setIsNavigationDrawerOpen, isMobileView}, props) {
	const addNotification = useNotificationsContext();

	const { tokens } = useTheme();
	const { window } = props;

	const [isDisplaySpinner, setIsDisplaySpinner] = useState(false);
	const [actualMenuItems, setActualMenuItems] = useState();
	useEffect(() => {
		(async function() {
			await retrieveData();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const retrieveData = async () => {
		setIsDisplaySpinner(true);
		try {
			let variables = {};
			if (localStorage.getItem("accountId")) {
				variables["account"] = localStorage.getItem("accountId");
			}
			const startTime = new Date().getTime(); // TODO debug
			console.log("== GRAPH-QL QUERY [getSidebarMenuItems] - START: "); // TODO debug
			const rawResponse = await API.graphql(graphqlOperation(getSidebarMenuItems, variables));
			console.log("== GRAPH-QL QUERY [getSidebarMenuItems] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
			let bufferItems = [];
			let resultMenuItems = (rawResponse.data.getSidebarMenuItems && isArray(rawResponse.data.getSidebarMenuItems.items))
				? rawResponse.data.getSidebarMenuItems.items
				: [];
			let currentContainerWithoutDividers = null;
			for (let currentItem of resultMenuItems) {
				let type = currentItem && currentItem.type ? currentItem.type : null;
				let icon = currentItem && currentItem.icon ? currentItem.icon : null;
				let title = currentItem && currentItem.title ? currentItem.title : null;
				let url = currentItem && currentItem.url ? currentItem.url : null;
				if (type && type === "menuItem") {
					if (!currentContainerWithoutDividers) {
						currentContainerWithoutDividers = [];
					}
					let IconInstance = AllIcons[icon];
					currentContainerWithoutDividers.push(
						<ListItem key={title} disablePadding className={"list-item-wrapper"}>
							<Link to={url} className={"list-item-link-wrapper"} style={{
								backgroundColor: `${themeBackgroundColor}`,
								color: `${themeColor}`
							}}>
								<ListItemIcon className={"list-item-link-icon-wrapper"} style={{
									backgroundColor: `${themeBackgroundColor}`,
									color: `${themeColor}`
								}}>
									<IconInstance />
								</ListItemIcon>
								<ListItemButton className={"list-item-link-button-wrapper"}>
									<ListItemText primary={title} className={"list-item-link-button-text-wrapper"}
									              disableTypography={true} />
								</ListItemButton>
							</Link>
						</ListItem>
					);
				}
				if (type && type === "divider") {
					if (currentContainerWithoutDividers) {
						bufferItems.push(<List>{currentContainerWithoutDividers}</List>);
						currentContainerWithoutDividers = null;
					}
					bufferItems.push(<Divider />);
				}
			}
			if (currentContainerWithoutDividers) {
				bufferItems.push(<List>{currentContainerWithoutDividers}</List>);
				currentContainerWithoutDividers = null;
			}
			// bufferItems.push(<Divider />);
			// bufferItems.push(
			// 	<List>
			// 		<ListItem key={"Sign Out"} disablePadding className={"list-item-wrapper"}>
			// 			<Button onClick={signOutFunction} variation={"primary"}>
			// 				Sign out
			// 			</Button>
			// 		</ListItem>
			// 	</List>
			// );
			setActualMenuItems(
				<Box sx={{ width: 250 }} role="presentation"
				     onClick={() => handleDrawerToggleForMobileAfterRedirect(false)}
				     onKeyDown={() => handleDrawerToggleForMobileAfterRedirect(false)}
				     style={{
					     backgroundColor: `${themeBackgroundColor}`,
					     color: `${themeColor}`,
					     height: "100%"
				     }}
				     fontFamily={tokens.fonts.default.variable.value}
				     fontSize={tokens.fontSizes.medium.value}
				>
					{bufferItems}
				</Box>
			);
		} catch (err) {
			apiErrorsHandler(err);
			setActualMenuItems(null);
		} finally {
			setIsDisplaySpinner(false);
		}
	};

	function apiErrorsHandler(err) {
		console.error(err);
		let errorMessage;
		if (err && err.response && err.response.data) {
			console.error(err.response.data);
			errorMessage = err.response.data;
		} else if (err.message) {
			console.error(err.message);
			errorMessage = err.message;
		} else {
			console.error(err);
			errorMessage = JSON.stringify(err);
		}
		if (errorMessage) {
			// noinspection JSValidateTypes
			addNotification({
				"componentName": componentName,
				"text": errorMessage,
				"type": "error",
				"seconds": 4,
				"header": undefined
			});
		}
	}

	// Specifying custom parameters for [sidebar] (or default values, if not specified)

	let themeBackgroundColor = null;
	let themeColor = null;

	const getSafeValueCssVariableWithSupportNestedValues = function (varName) {
		const startBracket = "var(";
		const endBracket = ")";
		if (varName && isString(varName) && varName.indexOf(startBracket) !== -1 && varName.indexOf(endBracket) !== -1) {
			let varNameWithoutBrackets = varName.substring(
				varName.indexOf(startBracket) + startBracket.length,
				varName.lastIndexOf(endBracket)
			);
			let appDomElement = document.getElementById("app-wrapper-id");
			if (appDomElement) {
				let allStylesForAppElement = getComputedStyle(appDomElement);
				if (allStylesForAppElement) {
					let actualValue = allStylesForAppElement.getPropertyValue(varNameWithoutBrackets);
					if (actualValue && isString(actualValue)) {
						if (actualValue.startsWith(startBracket)) {
							let nestedVarNameWithoutBrackets = actualValue.substring(
								actualValue.indexOf(startBracket) + startBracket.length,
								actualValue.lastIndexOf(endBracket)
							);
							// noinspection UnnecessaryLocalVariableJS
							let nestedActualValue = allStylesForAppElement.getPropertyValue(nestedVarNameWithoutBrackets);
							return nestedActualValue;
						} else {
							return actualValue;
						}
					} else {
						return actualValue;
					}
				}
			}
		}
		return varName;
	};

	// backgroundColor / color
	if (isObject(tokens) && !isArray(tokens) && isObject(tokens.components) && !isArray(tokens.components)
			&& isObject(tokens.components.sidebar) && !isArray(tokens.components.sidebar)) {
		if (isObject(tokens.components.sidebar.backgroundColor) && !isArray(tokens.components.sidebar.backgroundColor)) {
			themeBackgroundColor = `${tokens.components.sidebar.backgroundColor}`;
		}
		if (isObject(tokens.components.sidebar.color) && !isArray(tokens.components.sidebar.color)) {
			themeColor = `${tokens.components.sidebar.color}`;
		}
	}
	if (!themeBackgroundColor) {
		themeBackgroundColor = tokens.colors.background.tertiary.value;
	}
	if (!themeColor) {
		themeColor = tokens.colors.font.primary.value;
	}

	// // font / fontSize
	// if (isObject(tokens) && !isArray(tokens) && (isObject(tokens.fonts) && !isArray(tokens.fonts))
	// 		|| (isObject(tokens.fontSizes) && !isArray(tokens.fontSizes))) {
	// 	if (isObject(tokens.fonts) && !isArray(tokens.fonts)
	// 			&& isObject(tokens.fonts.default) && !isArray(tokens.fonts.default)
	// 			&& isObject(tokens.fonts.default.variable) && !isArray(tokens.fonts.default.variable)) {
	// 		themeFont = getSafeValueCssVariableWithSupportNestedValues(tokens.fonts.default.variable.value);
	// 	}
	// 	if (isObject(tokens.fontSizes) && !isArray(tokens.fontSizes)
	// 			&& isObject(tokens.fontSizes.medium) && !isArray(tokens.fontSizes.medium)) {
	// 		themeFontSize = getSafeValueCssVariableWithSupportNestedValues(tokens.fontSizes.medium.value);
	// 	}
	// }

	// Actual values

	themeBackgroundColor = getSafeValueCssVariableWithSupportNestedValues(themeBackgroundColor);
	themeColor = getSafeValueCssVariableWithSupportNestedValues(themeColor);

	const handleDrawerToggleForMobileAfterRedirect = (isOpen) => {
		if (isMobileView) {
			setIsNavigationDrawerOpen(isOpen);
		}
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: "flex" }} className={(className ? className : "")}>
			{ /*Loading spinner*/
				(isDisplaySpinner) &&
				<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				          open={isDisplaySpinner}>
					<CircularProgress color="inherit" />
				</Backdrop>
			}
			{/*{*/}
			{/*	<Button className={"hamburger-button"} variation="link" position="fixed"*/}
			{/*	        onClick={() => setIsNavigationDrawerOpen(!isNavigationDrawerOpen)}>*/}
			{/*		☰*/}
			{/*	</Button>*/}
			{/*}*/}
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="folders"
			     style={{ width: (isNavigationDrawerOpen) ? "unset" : "0" }}
			     className={"persistence-drawer-fix-nav"}
			>
				<Drawer container={container} open={isNavigationDrawerOpen}
				        onClose={() => setIsNavigationDrawerOpen(false)}
				        variant="persistent"
				        className={ "persistence-drawer-fix" }>
					<Divider />
					{actualMenuItems}
				</Drawer>
			</Box>
		</Box>
	);
}

Sidebar.propTypes = { window: PropTypes.func };

export default Sidebar;
