/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountConnectorForExists = /* GraphQL */ `
  query GetAccountConnectorForExists($account: String, $id: String!) {
    getAccountConnectorForExists(account: $account, id: $id) {
      apiId
      connectorMode {
        apiId
        authorisationMethod
        id
        modeDescription
        modeName
        __typename
      }
      connectorModeId
      createdBy
      id
      items {
        inputLabel
        inputType
        isReadonly
        isRequired
        lookupValuesToSelect {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        mask
        name
        placeholder
        possibleValuesArrayOfObjectsKeyValue {
          name
          value
          __typename
        }
        possibleValuesArrayOfStrings
        selectedLookupValueWrapper {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        value
        __typename
      }
      __typename
    }
  }
`;
export const getAccountConnectorForNew = /* GraphQL */ `
  query GetAccountConnectorForNew($account: String, $connectorModeId: String!) {
    getAccountConnectorForNew(
      account: $account
      connectorModeId: $connectorModeId
    ) {
      apiId
      connectorMode {
        apiId
        authorisationMethod
        id
        modeDescription
        modeName
        __typename
      }
      connectorModeId
      createdBy
      id
      items {
        inputLabel
        inputType
        isReadonly
        isRequired
        lookupValuesToSelect {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        mask
        name
        placeholder
        possibleValuesArrayOfObjectsKeyValue {
          name
          value
          __typename
        }
        possibleValuesArrayOfStrings
        selectedLookupValueWrapper {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        value
        __typename
      }
      __typename
    }
  }
`;
export const getAccountConnectorsList = /* GraphQL */ `
  query GetAccountConnectorsList($account: String, $nextToken: String) {
    getAccountConnectorsList(account: $account, nextToken: $nextToken) {
      nextToken
      tableData {
        cells {
          action
          buttonText
          graphqlParams {
            operation
            resultHandle {
              otherwiseAction {
                actionParams {
                  name
                  value
                  __typename
                }
                type
                __typename
              }
              success {
                andConditionAction {
                  actionParams {
                    name
                    value
                    __typename
                  }
                  type
                  __typename
                }
                criterias {
                  action {
                    actionParams {
                      name
                      value
                      __typename
                    }
                    type
                    __typename
                  }
                  condition
                  path
                  __typename
                }
                criteriasCondition
                __typename
              }
              __typename
            }
            type
            variables {
              name
              value
              __typename
            }
            __typename
          }
          linkText
          redirectParams {
            redirectPageName
            redirectPageUrl
            urlPathParams {
              name
              value
              __typename
            }
            urlQueryParams {
              name
              value
              __typename
            }
            __typename
          }
          type
          value
          __typename
        }
        headers
        __typename
      }
      __typename
    }
  }
`;
export const getAccountPageDefinition = /* GraphQL */ `
  query GetAccountPageDefinition($account: String, $id: String!) {
    getAccountPageDefinition(account: $account, id: $id) {
      views {
        accountTableViews {
          cells {
            action
            buttonText
            graphqlParams {
              operation
              resultHandle {
                otherwiseAction {
                  actionParams {
                    name
                    value
                    __typename
                  }
                  type
                  __typename
                }
                success {
                  andConditionAction {
                    actionParams {
                      name
                      value
                      __typename
                    }
                    type
                    __typename
                  }
                  criterias {
                    action {
                      actionParams {
                        name
                        value
                        __typename
                      }
                      type
                      __typename
                    }
                    condition
                    path
                    __typename
                  }
                  criteriasCondition
                  __typename
                }
                __typename
              }
              type
              variables {
                name
                value
                __typename
              }
              __typename
            }
            linkText
            redirectParams {
              redirectPageName
              redirectPageUrl
              urlPathParams {
                name
                value
                __typename
              }
              urlQueryParams {
                name
                value
                __typename
              }
              __typename
            }
            type
            value
            __typename
          }
          headers
          __typename
        }
        type
        __typename
      }
      __typename
    }
  }
`;
export const getAccountPageSection = /* GraphQL */ `
  query GetAccountPageSection(
    $account: String
    $datatableFilter: [DatatableFilterParamsInput]
    $id: String
  ) {
    getAccountPageSection(
      account: $account
      datatableFilter: $datatableFilter
      id: $id
    ) {
      datatable {
        filters {
          datetimeFormat
          filter
          placeholder
          type
          defaultText
          defaultNumber
          __typename
        }
        headers
        rows {
          cells {
            data
            type
            __typename
          }
          fields {
            inputLabel
            inputType
            value
            __typename
          }
          __typename
        }
        summaryFields {
          name
          value
          __typename
        }
        __typename
      }
      descriptionHeader
      descriptionText
      type
      __typename
    }
  }
`;
export const getAccountUser = /* GraphQL */ `
  query GetAccountUser($account: String, $id: String) {
    getAccountUser(account: $account, id: $id) {
      id
      items {
        inputLabel
        inputType
        isReadonly
        isRequired
        lookupValuesToSelect {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        mask
        name
        placeholder
        possibleValuesArrayOfObjectsKeyValue {
          name
          value
          __typename
        }
        possibleValuesArrayOfStrings
        selectedLookupValueWrapper {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        value
        __typename
      }
      username
      __typename
    }
  }
`;
export const getAccountUsersList = /* GraphQL */ `
  query GetAccountUsersList($account: String, $nextToken: String) {
    getAccountUsersList(account: $account, nextToken: $nextToken) {
      nextToken
      tableData {
        cells {
          action
          buttonText
          graphqlParams {
            operation
            resultHandle {
              otherwiseAction {
                actionParams {
                  name
                  value
                  __typename
                }
                type
                __typename
              }
              success {
                andConditionAction {
                  actionParams {
                    name
                    value
                    __typename
                  }
                  type
                  __typename
                }
                criterias {
                  action {
                    actionParams {
                      name
                      value
                      __typename
                    }
                    type
                    __typename
                  }
                  condition
                  path
                  __typename
                }
                criteriasCondition
                __typename
              }
              __typename
            }
            type
            variables {
              name
              value
              __typename
            }
            __typename
          }
          linkText
          redirectParams {
            redirectPageName
            redirectPageUrl
            urlPathParams {
              name
              value
              __typename
            }
            urlQueryParams {
              name
              value
              __typename
            }
            __typename
          }
          type
          value
          __typename
        }
        headers
        __typename
      }
      __typename
    }
  }
`;
export const getAccountWayForExists = /* GraphQL */ `
  query GetAccountWayForExists($account: String, $id: String!) {
    getAccountWayForExists(account: $account, id: $id) {
      id
      items {
        inputLabel
        inputType
        isReadonly
        isRequired
        lookupValuesToSelect {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        mask
        name
        placeholder
        possibleValuesArrayOfObjectsKeyValue {
          name
          value
          __typename
        }
        possibleValuesArrayOfStrings
        selectedLookupValueWrapper {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        value
        __typename
      }
      way {
        description
        id
        modelId
        name
        __typename
      }
      wayId
      __typename
    }
  }
`;
export const getAccountWayForNew = /* GraphQL */ `
  query GetAccountWayForNew($account: String, $wayId: String!) {
    getAccountWayForNew(account: $account, wayId: $wayId) {
      id
      items {
        inputLabel
        inputType
        isReadonly
        isRequired
        lookupValuesToSelect {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        mask
        name
        placeholder
        possibleValuesArrayOfObjectsKeyValue {
          name
          value
          __typename
        }
        possibleValuesArrayOfStrings
        selectedLookupValueWrapper {
          displayedValue
          keys {
            partitionKey
            partitionKeyValue
            sortKey
            sortKeyValue
            table
            __typename
          }
          __typename
        }
        value
        __typename
      }
      way {
        description
        id
        modelId
        name
        __typename
      }
      wayId
      __typename
    }
  }
`;
export const getAccountWaySectionsData = /* GraphQL */ `
  query GetAccountWaySectionsData(
    $account: String
    $existingAccountWayId: String
  ) {
    getAccountWaySectionsData(
      account: $account
      existingAccountWayId: $existingAccountWayId
    ) {
      existingAccountWayId
      sections {
        dataTable {
          actions {
            datatableActionCreateRowInModalParams {
              formFields {
                inputLabel
                inputType
                isReadonly
                isRequired
                lookupValuesToSelect {
                  displayedValue
                  keys {
                    partitionKey
                    partitionKeyValue
                    sortKey
                    sortKeyValue
                    table
                    __typename
                  }
                  __typename
                }
                mask
                name
                placeholder
                possibleValuesArrayOfObjectsKeyValue {
                  name
                  value
                  __typename
                }
                possibleValuesArrayOfStrings
                selectedLookupValueWrapper {
                  displayedValue
                  keys {
                    partitionKey
                    partitionKeyValue
                    sortKey
                    sortKeyValue
                    table
                    __typename
                  }
                  __typename
                }
                value
                __typename
              }
              mappingId
              refreshAfterCall
              __typename
            }
            id
            title
            type
            __typename
          }
          headers
          rows {
            cells {
              actions
              binding
              data
              type
              __typename
            }
            formFields {
              inputLabel
              inputType
              isReadonly
              isRequired
              lookupValuesToSelect {
                displayedValue
                keys {
                  partitionKey
                  partitionKeyValue
                  sortKey
                  sortKeyValue
                  table
                  __typename
                }
                __typename
              }
              mask
              name
              placeholder
              possibleValuesArrayOfObjectsKeyValue {
                name
                value
                __typename
              }
              possibleValuesArrayOfStrings
              selectedLookupValueWrapper {
                displayedValue
                keys {
                  partitionKey
                  partitionKeyValue
                  sortKey
                  sortKeyValue
                  table
                  __typename
                }
                __typename
              }
              value
              __typename
            }
            idsParams {
              key
              value
              __typename
            }
            __typename
          }
          __typename
        }
        formFields {
          inputLabel
          inputType
          isReadonly
          isRequired
          lookupValuesToSelect {
            displayedValue
            keys {
              partitionKey
              partitionKeyValue
              sortKey
              sortKeyValue
              table
              __typename
            }
            __typename
          }
          mask
          name
          placeholder
          possibleValuesArrayOfObjectsKeyValue {
            name
            value
            __typename
          }
          possibleValuesArrayOfStrings
          selectedLookupValueWrapper {
            displayedValue
            keys {
              partitionKey
              partitionKeyValue
              sortKey
              sortKeyValue
              table
              __typename
            }
            __typename
          }
          value
          __typename
        }
        header
        id
        title
        type
        __typename
      }
      __typename
    }
  }
`;
export const getAccountWaysList = /* GraphQL */ `
  query GetAccountWaysList($account: String, $nextToken: String) {
    getAccountWaysList(account: $account, nextToken: $nextToken) {
      nextToken
      tableData {
        cells {
          action
          buttonText
          graphqlParams {
            operation
            resultHandle {
              otherwiseAction {
                actionParams {
                  name
                  value
                  __typename
                }
                type
                __typename
              }
              success {
                andConditionAction {
                  actionParams {
                    name
                    value
                    __typename
                  }
                  type
                  __typename
                }
                criterias {
                  action {
                    actionParams {
                      name
                      value
                      __typename
                    }
                    type
                    __typename
                  }
                  condition
                  path
                  __typename
                }
                criteriasCondition
                __typename
              }
              __typename
            }
            type
            variables {
              name
              value
              __typename
            }
            __typename
          }
          linkText
          redirectParams {
            redirectPageName
            redirectPageUrl
            urlPathParams {
              name
              value
              __typename
            }
            urlQueryParams {
              name
              value
              __typename
            }
            __typename
          }
          type
          value
          __typename
        }
        headers
        __typename
      }
      __typename
    }
  }
`;
export const getAllowedAccounts = /* GraphQL */ `
  query GetAllowedAccounts($account: String) {
    getAllowedAccounts(account: $account) {
      items {
        id
        name
        __typename
      }
      __typename
    }
  }
`;
export const getAllowedPages = /* GraphQL */ `
  query GetAllowedPages($account: String) {
    getAllowedPages(account: $account) {
      items {
        alias
        caseSensitive
        exact
        pageName
        urlPath
        __typename
      }
      __typename
    }
  }
`;
export const getConnectorDefinitionList = /* GraphQL */ `
  query GetConnectorDefinitionList($account: String) {
    getConnectorDefinitionList(account: $account) {
      items {
        api
        description
        isActive
        logo
        __typename
      }
      __typename
    }
  }
`;
export const getConnectorDefinitionModesList = /* GraphQL */ `
  query GetConnectorDefinitionModesList($account: String, $api: String!) {
    getConnectorDefinitionModesList(account: $account, api: $api) {
      api {
        api
        description
        isActive
        logo
        __typename
      }
      defaultModeId
      items {
        apiId
        authorisationMethod
        id
        modeDescription
        modeName
        __typename
      }
      presentModeSelection
      __typename
    }
  }
`;
export const getCurrentAccount = /* GraphQL */ `
  query GetCurrentAccount($account: String) {
    getCurrentAccount(account: $account) {
      id
      name
      __typename
    }
  }
`;
export const genericAuthInitCallbackRedirect = /* GraphQL */ `
  query GenericAuthInitCallbackRedirect($account: String, $id: String!) {
    genericAuthInitCallbackRedirect(account: $account, id: $id) {
      htmlData
      redirectUrl
      __typename
    }
  }
`;
export const getPortalPageContent = /* GraphQL */ `
  query GetPortalPageContent($account: String, $id: String!) {
    getPortalPageContent(account: $account, id: $id) {
      index
      pageSectionId
      __typename
    }
  }
`;
export const getSidebarMenuItems = /* GraphQL */ `
  query GetSidebarMenuItems($account: String) {
    getSidebarMenuItems(account: $account) {
      items {
        icon
        title
        type
        url
        __typename
      }
      __typename
    }
  }
`;
export const getUploadSignedUrls = /* GraphQL */ `
  query GetUploadSignedUrls($account: String, $items: [UploadSignedUrlItem]) {
    getUploadSignedUrls(account: $account, items: $items)
  }
`;
export const getWayDefinitionList = /* GraphQL */ `
  query GetWayDefinitionList($account: String, $nextToken: String) {
    getWayDefinitionList(account: $account, nextToken: $nextToken) {
      items {
        description
        id
        modelId
        name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const handleCustomPage = /* GraphQL */ `
  query HandleCustomPage($account: String, $id: String!) {
    handleCustomPage(account: $account, id: $id) {
      accountWay {
        id
        items {
          inputLabel
          inputType
          isReadonly
          isRequired
          lookupValuesToSelect {
            displayedValue
            keys {
              partitionKey
              partitionKeyValue
              sortKey
              sortKeyValue
              table
              __typename
            }
            __typename
          }
          mask
          name
          placeholder
          possibleValuesArrayOfObjectsKeyValue {
            name
            value
            __typename
          }
          possibleValuesArrayOfStrings
          selectedLookupValueWrapper {
            displayedValue
            keys {
              partitionKey
              partitionKeyValue
              sortKey
              sortKeyValue
              table
              __typename
            }
            __typename
          }
          value
          __typename
        }
        way {
          description
          id
          modelId
          name
          __typename
        }
        wayId
        __typename
      }
      accountWaySections {
        existingAccountWayId
        sections {
          dataTable {
            actions {
              datatableActionCreateRowInModalParams {
                formFields {
                  inputLabel
                  inputType
                  isReadonly
                  isRequired
                  lookupValuesToSelect {
                    displayedValue
                    keys {
                      partitionKey
                      partitionKeyValue
                      sortKey
                      sortKeyValue
                      table
                      __typename
                    }
                    __typename
                  }
                  mask
                  name
                  placeholder
                  possibleValuesArrayOfObjectsKeyValue {
                    name
                    value
                    __typename
                  }
                  possibleValuesArrayOfStrings
                  selectedLookupValueWrapper {
                    displayedValue
                    keys {
                      partitionKey
                      partitionKeyValue
                      sortKey
                      sortKeyValue
                      table
                      __typename
                    }
                    __typename
                  }
                  value
                  __typename
                }
                mappingId
                refreshAfterCall
                __typename
              }
              id
              title
              type
              __typename
            }
            headers
            rows {
              cells {
                actions
                binding
                data
                type
                __typename
              }
              formFields {
                inputLabel
                inputType
                isReadonly
                isRequired
                lookupValuesToSelect {
                  displayedValue
                  keys {
                    partitionKey
                    partitionKeyValue
                    sortKey
                    sortKeyValue
                    table
                    __typename
                  }
                  __typename
                }
                mask
                name
                placeholder
                possibleValuesArrayOfObjectsKeyValue {
                  name
                  value
                  __typename
                }
                possibleValuesArrayOfStrings
                selectedLookupValueWrapper {
                  displayedValue
                  keys {
                    partitionKey
                    partitionKeyValue
                    sortKey
                    sortKeyValue
                    table
                    __typename
                  }
                  __typename
                }
                value
                __typename
              }
              idsParams {
                key
                value
                __typename
              }
              __typename
            }
            __typename
          }
          formFields {
            inputLabel
            inputType
            isReadonly
            isRequired
            lookupValuesToSelect {
              displayedValue
              keys {
                partitionKey
                partitionKeyValue
                sortKey
                sortKeyValue
                table
                __typename
              }
              __typename
            }
            mask
            name
            placeholder
            possibleValuesArrayOfObjectsKeyValue {
              name
              value
              __typename
            }
            possibleValuesArrayOfStrings
            selectedLookupValueWrapper {
              displayedValue
              keys {
                partitionKey
                partitionKeyValue
                sortKey
                sortKeyValue
                table
                __typename
              }
              __typename
            }
            value
            __typename
          }
          header
          id
          title
          type
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const testWAY389 = /* GraphQL */ `
  query TestWAY389($account: String) {
    testWAY389(account: $account)
  }
`;
