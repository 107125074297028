// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@aws-amplify/ui-react";

import { PortalHeader } from "../../ui-components";
import ManageAccountsModal from "../ManageAccountsModal/ManageAccountsModal";

import { getPathByPageName, isPagePathExists } from "../../routerPaths";
import { useNotificationsContext } from "../Notifications/NotificationsAndNotificationsContext";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function isString(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (typeof obj === "string" || obj instanceof String) ? true : false;
}
function isObject(obj) {
	// noinspection RedundantConditionalExpressionJS
	return typeof obj === "object" && obj !== null ? true : false;
}
function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}

const componentName = "FigmaCmpWrapperPortalHeader";
export default function FigmaCmpWrapperPortalHeader({ className, isNavigationDrawerOpen, setIsNavigationDrawerOpen, signOutFunction }) {
	const addNotification = useNotificationsContext();
	const navigate = useNavigate();
	const { tokens } = useTheme();

	// Specifying custom parameters for [portal-header] (or default values, if not specified)

	let themeBackgroundColor = null;
	let themeColor = null;
	let themeFont = null;
	let themeFontSize = null;

	const getSafeValueCssVariableWithSupportNestedValues = function (varName) {
		const startBracket = "var(";
		const endBracket = ")";
		if (varName && isString(varName) && varName.indexOf(startBracket) !== -1 && varName.indexOf(endBracket) !== -1) {
			let varNameWithoutBrackets = varName.substring(
				varName.indexOf(startBracket) + startBracket.length,
				varName.lastIndexOf(endBracket)
			);
			let appDomElement = document.getElementById("app-wrapper-id");
			if (appDomElement) {
				let allStylesForAppElement = getComputedStyle(appDomElement);
				if (allStylesForAppElement) {
					let actualValue = allStylesForAppElement.getPropertyValue(varNameWithoutBrackets);
					if (actualValue && isString(actualValue)) {
						if (actualValue.startsWith(startBracket)) {
							let nestedVarNameWithoutBrackets = actualValue.substring(
								actualValue.indexOf(startBracket) + startBracket.length,
								actualValue.lastIndexOf(endBracket)
							);
							// noinspection UnnecessaryLocalVariableJS
							let nestedActualValue = allStylesForAppElement.getPropertyValue(nestedVarNameWithoutBrackets);
							return nestedActualValue;
						} else {
							return actualValue;
						}
					} else {
						return actualValue;
					}
				}
			}
		}
		return varName;
	};

	// backgroundColor / color / font / fontSize
	if (isObject(tokens) && !isArray(tokens) && isObject(tokens.components) && !isArray(tokens.components)
			&& isObject(tokens.components.portalHeader) && !isArray(tokens.components.portalHeader)) {
		if (isObject(tokens.components.portalHeader.backgroundColor) && !isArray(tokens.components.portalHeader.backgroundColor)) {
			themeBackgroundColor = `${tokens.components.portalHeader.backgroundColor}`;
		}
		if (isObject(tokens.components.portalHeader.color) && !isArray(tokens.components.portalHeader.color)) {
			themeColor = `${tokens.components.portalHeader.color}`;
		}
		if (isObject(tokens.components.portalHeader.font) && !isArray(tokens.components.portalHeader.font)) {
			themeFont = `${tokens.components.portalHeader.font}`;
		}
		if (isObject(tokens.components.portalHeader.fontSize) && !isArray(tokens.components.portalHeader.fontSize)) {
			themeFontSize = `${tokens.components.portalHeader.fontSize}`;
		}
	}
	if (!themeBackgroundColor) {
		themeBackgroundColor = "rgba(24,18,45,1)"; // Default value is hardcode-value (to match logo color)
	}
	if (!themeColor) {
		themeColor = tokens.colors.font.inverse.value;
	}
	if (!themeFont) {
		themeFont = tokens.fonts.default.variable.value;
	}
	if (!themeFontSize) {
		themeFontSize = tokens.fontSizes.medium.value;
	}

	// Actual values

	themeBackgroundColor = getSafeValueCssVariableWithSupportNestedValues(themeBackgroundColor);
	themeColor = getSafeValueCssVariableWithSupportNestedValues(themeColor);
	themeFont = getSafeValueCssVariableWithSupportNestedValues(themeFont);
	themeFontSize = getSafeValueCssVariableWithSupportNestedValues(themeFontSize);

	function portalLogoOnClickHandler() {
		try {
			const pageName = "PortalWelcomePage";
			let pageUrl = getPathByPageName(pageName);
			if (!isPagePathExists(pageUrl)) {
				// eslint-disable-next-line no-throw-literal
				throw "Specified page does not exist on the portal: " + pageUrl;
			}
			navigate({ "pathname": pageUrl });
		} catch (ex) {
			console.error(ex);
			// noinspection JSValidateTypes
			addNotification({
				"componentName": componentName,
				"text": ex && ex.message ? ex.message : ex,
				"type": "error",
				"seconds": 4,
				"header": undefined
			});
		}
	}

	const [isAccountsModalOpen, setIsAccountsModalOpen] = useState(false);

	return (
		<div className={(className ? className : "")} style={{
			gridArea: "1 / 1 / 2 / 2",
			position: "sticky",
			top: "0",
			zIndex: "1201"
		}}>
			<PortalHeader style={{
				flexWrap: "wrap"
			}} overrides={{
				"PortalHeader": {
					width: "unset",
					gap: "1rem",
					// backgroundColor: themeBackgroundColor
				},
				"ImageLogo": {
					src: "/Waysayer_BgDark6_noShadow_for_Portal_export.svg",
					style: {
						cursor: "pointer"
					},
					onClick: () => { portalLogoOnClickHandler() }
				},
				"ButtonMenu": {
					children: "☰",
					variation: "link",
					className: "main-menu-button",
					style: {
						fontSize: "2rem",
						outline: "0",
						color: "#4949D1",
						border: "none"
					},
					onClick: () => { setIsNavigationDrawerOpen(!isNavigationDrawerOpen); }
				},
				// "ButtonMFA": {
				// 	children: "MFA",
				// 	onClick: () => { showMfaModalCallback() }
				// },
				// "AccountTitle": {
				// 	color: themeColor,
				// 	fontFamily: themeFont,
				// 	fontSize: themeFontSize
				// },
				// "AccountValue": {
				// 	color: themeColor,
				// 	fontFamily: themeFont,
				// 	fontSize: themeFontSize,
				// 	children: undefined
				// },
				"FrameAccountPanel": {
					className: "account-button-top-bar" + ((isAccountsModalOpen) ? " account-button-top-bar-active" : ""),
					style: {
						cursor: "pointer"
					},
					onClick: () => {
						setIsAccountsModalOpen(true);
					}
				},
				"TextPanelIcon": {
					children: <FontAwesomeIcon icon={faUser} />,
					title: "Manage Account"
				}
			}} />

			<ManageAccountsModal isModalOpen={isAccountsModalOpen} setIsModalOpen={setIsAccountsModalOpen}
				signOutFunction={signOutFunction}  />
		</div>
	);
}
