/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PortalHeader(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="650px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="10px 0px 10px 15px"
      backgroundColor="rgba(41,41,129,1)"
      {...getOverrideProps(overrides, "PortalHeader")}
      {...rest}
    >
      <Flex
        gap="14px"
        direction="row"
        width="253px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "FrameMenuButtonAndLogo")}
      >
        <Button
          width="unset"
          height="unset"
          padding="5px 5px 5px 5px"
          shrink="0"
          backgroundColor="rgba(255,255,255,0)"
          size="default"
          isDisabled={false}
          variation="link"
          children="M"
          {...getOverrideProps(overrides, "ButtonMenu")}
        ></Button>
        <Image
          width="212px"
          height="52px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "ImageLogo")}
        ></Image>
      </Flex>
      <Flex
        gap="30px"
        direction="row"
        width="46px"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 5px 0px 5px"
        {...getOverrideProps(overrides, "FrameAccountPanel")}
      >
        <Text
          fontFamily="Font Awesome 5 Free"
          fontSize="26px"
          fontWeight="400"
          color="rgba(73,73,209,1)"
          lineHeight="29.899999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children=""
          {...getOverrideProps(overrides, "TextPanelIcon")}
        ></Text>
      </Flex>
    </Flex>
  );
}
