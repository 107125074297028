// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
	Authenticator,
	withAuthenticator,
	ThemeProvider,
	defaultDarkModeOverride,
	View
} from "@aws-amplify/ui-react";
import { createTheme } from "@aws-amplify/ui";

import { studioTheme } from "./ui-components";

import React, { lazy, useEffect, useState, Suspense } from "react";
import { updateRouterPaths } from "./routerPaths";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { useMediaQuery } from "react-responsive";

import { API, graphqlOperation } from "aws-amplify";
import { getCurrentAccount, getAllowedPages } from "./graphql/queries.ts";

import {
	useNotificationsContext, NotificationsContextProvider
} from "./components/Notifications/NotificationsAndNotificationsContext";

import Sidebar from "./components/Sidebar/Sidebar";
import FigmaCmpWrapperPortalHeader from "./components/FigmaCmpWrapper-PortalHeader/FigmaCmpWrapperPortalHeader";
// import TopBar from "./components/TopBar/TopBar";
// import MfaModal from "./components/MfaModal/MfaModal";

// Helper functions

function isObject(obj) {
	// noinspection RedundantConditionalExpressionJS
	return typeof obj === "object" && obj !== null ? true : false;
}

function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}

const componentName = "App";
function App() {
	// const [isShowMfaModal, setShowMfaModal] = useState(false);
	//
	// const showMfaModalHandler = async () => {
	// 	setShowMfaModal(true);
	// }
	// const closeMfaModalHandler = async () => {
	// 	setShowMfaModal(false);
	// }

	const addNotification = useNotificationsContext();
	const [isDisplaySpinner, setIsDisplaySpinner] = useState(false);

	const isMobileView = useMediaQuery({ query: `(max-width: 720px)` });
	const [ isNavigationDrawerOpen, setIsNavigationDrawerOpen ] = React.useState(!isMobileView);

	const [ routerPaths, setRouterPaths ] = useState();
	useEffect(() => {
		(async function() {
			await validateAccount();
			await retrieveRouterPaths();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateAccount = async () => {
		try {
			let variables = {};
			if (localStorage.getItem("accountId")) {
				variables["account"] = localStorage.getItem("accountId");
			}
			let rawResponse;
			const startTime = new Date().getTime(); // TODO debug
			console.log("== GRAPH-QL QUERY [getCurrentAccount] - START: "); // TODO debug
			rawResponse = await API.graphql(graphqlOperation(getCurrentAccount, variables));
			console.log("== GRAPH-QL QUERY [getCurrentAccount] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
			let newAccountWrapper = (rawResponse && rawResponse.data && rawResponse.data.getCurrentAccount)
				? rawResponse.data.getCurrentAccount
				: null;
			if (newAccountWrapper && newAccountWrapper.id) {
				localStorage.setItem("accountId", newAccountWrapper.id);
			}
		} catch (err) {
			apiErrorsHandler(err);
		}
	};

	const retrieveRouterPaths = async () => {
		setIsDisplaySpinner(true);
		try {
			let variables = {};
			if (localStorage.getItem("accountId")) {
				variables["account"] = localStorage.getItem("accountId");
			}
			const startTime = new Date().getTime(); // TODO debug
			console.log("== GRAPH-QL QUERY [getAllowedPages] - START: "); // TODO debug
			const rawResponse = await API.graphql(graphqlOperation(getAllowedPages, variables));
			console.log("== GRAPH-QL QUERY [getAllowedPages] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
			if (rawResponse.data.getAllowedPages.items) {
				let routerPathsForUpdate = [];
				for (let item of rawResponse.data.getAllowedPages.items) {
					let pageName = item.pageName;
					let ComponentInstance = await lazy(() => import(
						`./pages/${pageName}`
					));
					routerPathsForUpdate.push(
						{
							alias: item.alias,
							path: item.urlPath,
							exact: item.exact,
							caseSensitive: item.caseSensitive,
							element: ComponentInstance
						}
					);
				}
				updateRouterPaths(routerPathsForUpdate);
				setRouterPaths(routerPathsForUpdate);
			}
		} catch (err) {
			apiErrorsHandler(err);
		} finally {
			setIsDisplaySpinner(false);
		}
	};

	function apiErrorsHandler(err) {
		console.error(err);
		let errorMessage;
		if (err && err.response && err.response.data) {
			console.error(err.response.data);
			errorMessage = err.response.data;
		} else if (err.message) {
			console.error(err.message);
			errorMessage = err.message;
		} else {
			console.error(err);
			errorMessage = JSON.stringify(err);
		}
		if (errorMessage) {
			// noinspection JSValidateTypes
			addNotification({
				"componentName": componentName,
				"text": errorMessage,
				"type": "error",
				"seconds": 4,
				"header": undefined
			});
		}
	}

	let isReactAppAmplifyThemeProviderDebugMode = process.env.REACT_APP_AMPLIFY_THEME_PROVIDER_DEBUG_MODE === true
		|| process.env.REACT_APP_AMPLIFY_THEME_PROVIDER_DEBUG_MODE === "true";

	let unifiedTheme = undefined;

	// Defining a theme from Figma

	let isNeedSpecifyDefaultDarkModeOverride = false;
	if (studioTheme && isObject(studioTheme)) {
		unifiedTheme = createTheme(studioTheme);
		isNeedSpecifyDefaultDarkModeOverride = true;
	}

	// If [ColorMode] is specified - always specify the option [defaultDarkModeOverride]

	let actualAmplifyThemeProviderColorMode = process.env.REACT_APP_AMPLIFY_THEME_PROVIDER_COLOR_MODE;
	if (actualAmplifyThemeProviderColorMode) {
		isNeedSpecifyDefaultDarkModeOverride = true;
	}

	// Defining a custom theme from env.var [REACT_APP_AMPLIFY_THEME_PROVIDER_THEME] - parsing and merging.

	let rawAmplifyThemeProviderThemeCustomOverride = process.env.REACT_APP_AMPLIFY_THEME_PROVIDER_THEME;
	try {
		if (rawAmplifyThemeProviderThemeCustomOverride) {
			rawAmplifyThemeProviderThemeCustomOverride = JSON.parse(rawAmplifyThemeProviderThemeCustomOverride);
			if (isObject(rawAmplifyThemeProviderThemeCustomOverride)) {
				isNeedSpecifyDefaultDarkModeOverride = true;
				if (unifiedTheme && isObject(unifiedTheme)) {
					unifiedTheme = createTheme(rawAmplifyThemeProviderThemeCustomOverride, unifiedTheme);
				} else {
					unifiedTheme = createTheme(rawAmplifyThemeProviderThemeCustomOverride);
				}
			}
		}
	} catch (ex) {
		if (isReactAppAmplifyThemeProviderDebugMode) {
			console.error("Error parsing parameter [REACT_APP_AMPLIFY_THEME_PROVIDER_THEME]");
			console.error(ex);
		}
	}

	let fontsOverride = {
		"tokens":{
			"fonts":{
				"default":{
					"variable":{
						"value":"'Poppins-Light', 'InterVariable', 'Inter var', 'Inter', -apple-system, " +
							"BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Ubuntu, Cantarell, " +
							"'Open Sans', sans-serif"
					},
					"static":{
						"value":"'Poppins-Light', 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', " +
							"'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif"
					}
				}
			}
		}
	};
	if (unifiedTheme && isObject(unifiedTheme)) {
		unifiedTheme = createTheme(fontsOverride, unifiedTheme);
	} else {
		unifiedTheme = createTheme(fontsOverride);
	}

	if (isNeedSpecifyDefaultDarkModeOverride) {
		if (!unifiedTheme) {
			// parameter - default override for "dark mode"
			unifiedTheme = createTheme({ name: "base-extension", overrides: [ defaultDarkModeOverride ] });
		} else {
			if (isArray(unifiedTheme.overrides)) {
				unifiedTheme.overrides.push(defaultDarkModeOverride);
			} else if (!unifiedTheme.overrides) {
				unifiedTheme.overrides = [ defaultDarkModeOverride ];
			}
		}
	}

	if (isReactAppAmplifyThemeProviderDebugMode) {
		console.log("------------------------------------------------------------------------------------------------");
		console.log("Debug [ThemeProvider]");
		console.log("Param [colorMode] (REACT_APP_AMPLIFY_THEME_PROVIDER_COLOR_MODE):", actualAmplifyThemeProviderColorMode);
		console.log("Param [theme] (REACT_APP_AMPLIFY_THEME_PROVIDER_THEME), after merge with standards:");
		console.log(JSON.parse(JSON.stringify(unifiedTheme)));
	}

	return (
		<ThemeProvider
				theme={unifiedTheme ? unifiedTheme : null}
				colorMode={actualAmplifyThemeProviderColorMode ? actualAmplifyThemeProviderColorMode : null}>
			<Authenticator>
				{({ signOut, user }) => (
					<View className="App" backgroundColor="var(--amplify-colors-background-secondary)"
					      id="app-wrapper-id">
						{user && (
							<NotificationsContextProvider>
								{/*{isShowMfaModal && (*/}
								{/*	<MfaModal user={user} closeModalCallback={closeMfaModalHandler} />*/}
								{/*)}*/}
								<Router>
									<FigmaCmpWrapperPortalHeader isNavigationDrawerOpen={isNavigationDrawerOpen}
										setIsNavigationDrawerOpen={setIsNavigationDrawerOpen} signOutFunction={signOut} />
									<div className={ "pages-content-wrapper" }>
										<Sidebar className={ "page-sidebar" } signOutFunction={signOut}
										         isNavigationDrawerOpen={isNavigationDrawerOpen}
										         setIsNavigationDrawerOpen={setIsNavigationDrawerOpen}
										         isMobileView={isMobileView} />
										{/*<div style={{ background: "#2c3e50", padding: "1em", color: "#e0faf4" }}>*/}
										{/*	App bar demo*/}
										{/*</div>*/}
										{/*<TopBar />*/}
										{/*<hr style={{ color: "#5C5CFF", margin: "0" }}/>/!*<!-- TODO debug -->*!/*/}

										{ /*Loading spinner*/
											(isDisplaySpinner) &&
											<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
											          open={isDisplaySpinner}>
												<CircularProgress color="inherit" />
											</Backdrop>
										}

										{
											(routerPaths) &&
											<Suspense fallback={<div>Loading...</div>}>
												<Routes>
													{
														routerPaths.map((route, index) => (
															<Route
																key={index}
																path={route.path}
																exact={route.exact}
																caseSensitive={route.caseSensitive}
																element={<route.element/>}
															/>
														))
													}
												</Routes>
											</Suspense>
										}
									</div>
								</Router>
							</NotificationsContextProvider>
						)}
					</View>
				)}
			</Authenticator>
		</ThemeProvider>
	);
}

export default withAuthenticator(App, {
	hideSignUp: true
});
