// noinspection DuplicatedCode,ExceptionCaughtLocallyJS

import "./ManageAccountsModal.css";

import Modal from "@mui/material/Modal";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useEffect, useState } from "react";

import {
	Divider,
	Heading
} from "@aws-amplify/ui-react";

import { Auth, API, graphqlOperation } from "aws-amplify";
import { getCurrentAccount, getAllowedAccounts } from "../../graphql/queries.ts";

import AccountSwitcherInline from "../../components/AccountSwitcherInline/AccountSwitcherInline";

import { useNotificationsContext } from "../Notifications/NotificationsAndNotificationsContext";

function isArray(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (obj instanceof Array) ? true : false;
}
function isObject(obj) {
	// noinspection RedundantConditionalExpressionJS
	return typeof obj === "object" && obj !== null ? true : false;
}
function isString(obj) {
	// noinspection RedundantConditionalExpressionJS
	return (typeof obj === "string" || obj instanceof String) ? true : false;
}

// eslint-disable-next-line no-unused-vars
const componentName = "ManageAccountsModal";
function ManageAccountsModal({ className, isModalOpen, setIsModalOpen, signOutFunction }) {
	const addNotification = useNotificationsContext();

	const [accountWrapper, setAccountWrapper] = useState(null);
	const [cognitoUserInfo, setCognitoUserInfo] = useState(null);
	const [allowedAccounts, setAllowedAccounts] = useState([]);

	const [isAllowSwitchAccounts, setIsAllowSwitchAccounts] = useState(false);

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		(async function() {
			await retrieveDataCurrentAccount();
			await retrieveDataAllowedAccounts();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const retrieveDataCurrentAccount = async () => {
		try {
			let variables = {};
			if (localStorage.getItem("accountId")) {
				variables["account"] = localStorage.getItem("accountId");
			}
			let rawResponse;
			const startTime = new Date().getTime(); // TODO debug
			console.log("== GRAPH-QL QUERY [getCurrentAccount] - START: "); // TODO debug
			rawResponse = await API.graphql(graphqlOperation(getCurrentAccount, variables));
			console.log("== GRAPH-QL QUERY [getCurrentAccount] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
			let newAccountWrapper = (rawResponse && rawResponse.data && rawResponse.data.getCurrentAccount)
				? rawResponse.data.getCurrentAccount
				: null;
			if (newAccountWrapper && isObject(newAccountWrapper) && newAccountWrapper.name) {
				// setAccountName(newAccountWrapper.name);
			}
			setAccountWrapper(newAccountWrapper);

			let user = await Auth.currentUserInfo();
			setCognitoUserInfo(user);
		} catch (err) {
			apiErrorsHandler(err);
		}
	};

	const retrieveDataAllowedAccounts = async () => {
		try {
			let variables = {
				"account": localStorage.getItem("accountId")
			};
			let rawResponse;
			const startTime = new Date().getTime(); // TODO debug
			console.log("== GRAPH-QL QUERY [getAllowedAccounts] - START: "); // TODO debug
			rawResponse = await API.graphql(graphqlOperation(getAllowedAccounts, variables));
			console.log("== GRAPH-QL QUERY [getAllowedAccounts] - END: " + ((new Date().getTime()) - startTime) + "(ms)"); // TODO debug
			let allowedAccounts = (
				rawResponse && rawResponse.data && rawResponse.data.getAllowedAccounts
				&& isArray(rawResponse.data.getAllowedAccounts.items)
			)
				? rawResponse.data.getAllowedAccounts.items
				: null;
			setAllowedAccounts(allowedAccounts);
			setIsAllowSwitchAccounts(isArray(allowedAccounts) && allowedAccounts.length > 1);
		} catch (err) {
			setIsAllowSwitchAccounts(false);
			apiErrorsHandler(err);
		} finally {
			// setIsDisplaySpinner(false);
		}
	};

	function apiErrorsHandler(err) {
		console.error(err);
		let errorMessage = "";
		if (err && err.response && err.response.data) {
			console.error(err.response.data);
			errorMessage = err.response.data;
		} else if (err.message) {
			console.error(err.message);
			errorMessage = err.message;
		} else {
			console.error(err);
			errorMessage = JSON.stringify(err);
		}
		if (errorMessage) {
			// noinspection JSValidateTypes
			addNotification({
				"componentName": componentName,
				"text": errorMessage,
				"type": "error",
				"seconds": 4,
				"header": undefined
			});
		}
	}

	const logOutOnClickHandler = async () => {
		await signOutFunction();
	}

	return (
		<Modal open={isModalOpen} onClose={handleModalClose}
		       slotProps={{ backdrop: { style: { backgroundColor: "unset" } } }}>
			<div className={(className ? className : "")}>
				<div className="accounts-modal-window">
					<Heading level={6} style={{ width: "100%" }}>Account</Heading>
					{
						(!isAllowSwitchAccounts) &&
						<p>{isObject(accountWrapper) && accountWrapper.name ? accountWrapper.name : "undefined"}</p>
					}
					{
						(isAllowSwitchAccounts) &&
						<AccountSwitcherInline allowedAccounts={allowedAccounts}/>
					}
					<p>
						{(() => {
							if (isObject(cognitoUserInfo)) {
								if (isObject(cognitoUserInfo.attributes) && cognitoUserInfo.attributes.name) {
									if (cognitoUserInfo.username) {
										return cognitoUserInfo.attributes.name + " / " + cognitoUserInfo.username;
									}
									return cognitoUserInfo.attributes.name;
								} else if (cognitoUserInfo.username) {
									return cognitoUserInfo.username;
								} else if (isObject(cognitoUserInfo.attributes) && cognitoUserInfo.attributes.email) {
									return cognitoUserInfo.attributes.email;
								} else if (isObject(cognitoUserInfo.attributes) && cognitoUserInfo.attributes.nickname) {
									return cognitoUserInfo.attributes.nickname;
								} else {
									return ("undefined");
								}
							} else {
								return ("undefined");
							}
						})()}
					</p>
					<Divider orientation="horizontal" size="small" />
					{/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
					<a href={undefined} onClick={() => logOutOnClickHandler()}>Log Out</a>
				</div>
			</div>
		</Modal>
	);
}

export default ManageAccountsModal;
