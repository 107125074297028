import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify /*, Auth */ } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import awsConfig from "./aws-exports";

let amplifyConfig = {
	...awsConfig
};

try {
	if (process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE && process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT) {
		amplifyConfig.aws_appsync_authenticationType = process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE;
		amplifyConfig.aws_appsync_graphqlEndpoint = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT;
	} else {
		if (!(window && window.location && window.location.hostname && window.location.hostname === "localhost")) {
			// eslint-disable-next-line no-throw-literal
			throw "Environment variables [VUE_APP_AWS_APPSYNC_GRAPHQLENDPOINT] and [VUE_APP_AWS_APPSYNC_AUTHENTICATIONTYPE] not specified.";
		}
	}
} catch (ex) {
	console.warn(ex);
}

// Change site icon from environment variable (optional)
if (process.env.REACT_APP_SITE_FAVICON_URL) {
	try {
		let iconHtmlElement = document.querySelector("link[rel~=\"icon\"]");
		if (!iconHtmlElement) {
			// eslint-disable-next-line no-throw-literal
			throw "HTML-tag link [link rel=\"icon\"] not found.";
		}
		iconHtmlElement.setAttribute("href", process.env.REACT_APP_SITE_FAVICON_URL);
	} catch (ex) {
		console.warn(ex);
	}
}

// Change site icon from environment variable (optional)
if (process.env.REACT_APP_SITE_TITLE) {
	try {
		document.title = process.env.REACT_APP_SITE_TITLE;
	} catch (ex) {
		console.warn(ex);
	}
}

// Auth.configure(amplifyConfig);
Amplify.configure(amplifyConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AmplifyProvider>
			<App />
		</AmplifyProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
