
let routerPaths = [];

export const updateRouterPaths = (routerPathsForUpdate) => {
	routerPaths = routerPathsForUpdate;
}

export const isPagePathExists = (pagePath) => {
	function isString(obj) {
		// noinspection RedundantConditionalExpressionJS
		return (typeof obj === "string" || obj instanceof String) ? true : false;
	}
	function isObject(obj) {
		// noinspection RedundantConditionalExpressionJS
		return typeof obj === "object" && obj !== null ? true : false;
	}
	if (!pagePath || !isString(pagePath)) {
		// eslint-disable-next-line
		throw "[pagePath] parameter is not a string: " + pagePath;
	}
	for (let routerPathWrapper of routerPaths) {
		if (routerPathWrapper && isObject(routerPathWrapper) && isString(routerPathWrapper.path)
				&& routerPathWrapper.path.trim().toLowerCase() === pagePath.trim().toLowerCase()) {
			return true;
		}
	}
	return false;
}

export const getPathByPageName = (pageAlias) => {
	function isString(obj) {
		// noinspection RedundantConditionalExpressionJS
		return (typeof obj === "string" || obj instanceof String) ? true : false;
	}
	function isObject(obj) {
		// noinspection RedundantConditionalExpressionJS
		return typeof obj === "object" && obj !== null ? true : false;
	}
	if (!pageAlias || !isString(pageAlias)) {
		// eslint-disable-next-line
		throw "[pageAlias] parameter is not a string: " + pageAlias;
	}
	for (let routerPathWrapper of routerPaths) {
		if (routerPathWrapper && isObject(routerPathWrapper) && isString(routerPathWrapper.alias)
				&& routerPathWrapper.alias.trim().toLowerCase() === pageAlias.trim().toLowerCase()) {
			return routerPathWrapper.path;
		}
	}
	// eslint-disable-next-line no-throw-literal
	throw "Page with specified alias not found: " + pageAlias;
}

export default routerPaths;
